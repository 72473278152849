.column.main {
    .wheel-product-notification {
        color: $color-white;
        font-size: 20px;
        padding: 0 15px 15px;

        p {
            margin: auto;
        }

        a {
            color: $color-white  !important;
            text-decoration: underline;
        }

        .wheel-product-notification-nomatch,
        .wheel-product-notification-notselected {
            background-color: $brand__primary__color;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .wheel-product-notification-match {
            background-color: $brand__cta__color;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
