.column.main {
    .wheels-configurator-top {
        background-image: url("/media/banners/wheels-config-bg.jpeg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 150px 0 25px;

        @include min-screen($screen__l) {
            -webkit-font-smoothing: antialiased;
        }

        @include min-screen($screen__l) {
            padding: 415px 0 25px;

        }

        .widget {
            margin-bottom: 15px;

            p {
                color: $color-white;
                font-size: 24px;
                line-height: 22px;
                margin: 0;
                padding-left: 20px;

                @include min-screen($screen__l) {
                    font-size: 32px;
                    line-height: 30px;
                }
            }
        }

        .amfinder-common-wrapper {
            background: none;
            border: none;

            .amfinder-title {
                display: none;
            }

            .amfinder-items {
                .amfinder-buttons {
                    .button.reset {
                        color: $color-white;
                    }
                }

                .amfinder-edit-item {

                    .amfinder-edit__rendered-content,
                    .button.reset {
                        color: $color-white;
                    }
                }
            }
        }
    }

    .wheels-configurator-bottom {
        @include min-screen($screen__l) {
            -webkit-font-smoothing: antialiased;
        }

        .widget {
            margin: 30px 0;

            ul {
                list-style-type: none;
                align-items: center;
                justify-content: space-between;
                padding: 0;
                gap: 25px;
                grid-template-columns: 1fr 1fr;
                display: grid;

                @include min-screen($screen__l) {
                    display: flex;
                    gap: 50px;
                }

                li {
                    width: 100%;

                    @include min-screen($screen__l) {
                        width: 25%;
                    }

                    a,
                    picture,
                    img {
                        width: 100%;
                    }
                }
            }
        }

        .widget-banners {
            .widget-banner {
                .widget-banner__inner {
                    @include min-screen($screen__l) {
                        overflow: hidden;
                        height: 320px;
                    }

                    .widget-banner__title {
                        color: $color-white;
                        font-size: 22px;
                        margin: 0;
                        padding-left: 20px;
                        text-transform: none;

                        @include min-screen($screen__l) {
                            font-size: 52px;
                            line-height: 60px;
                        }
                    }
                }
            }
        }
    }
}
