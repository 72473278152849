//
//  Variables
//  _____________________________________________

$checkout-payment-option-title__border            : $checkout-payment-method-title__border !default;
$checkout-payment-option-title__color             : $text__color !default;
$checkout-payment-option-title__padding           : $checkout-payment-method-title__padding !default;
$checkout-payment-option-title-mobile__padding    : $checkout-payment-method-title-mobile__padding !default;

$checkout-payment-option-title-icon__font-size    : 32px !default;
$checkout-payment-option-title-icon__line-height  : 16px !default;
$checkout-payment-option-title-icon__margin       : 0 !default;
$checkout-payment-option-title-icon__color        : $minicart-icons-color !default;
$checkout-payment-option-title-icon__hover__color : $primary__color !default;

$checkout-payment-option-content__padding__xl     : $checkout-payment-method-content__padding__xl !default;

.checkout-payment-method {
    hr,
    #recaptcha-checkout-place-order-wrapper {
        display: none;
    }

    .payment-option {
        .payment-option-content {
            padding: $indent__base;

            form.order-comment-form {
                max-width: 100%;

                .payment-option-inner {
                    width: 100%;

                    textarea {
                        resize: none;

                        &::placeholder {
                            opacity: 0;
                        }
                    }
                }
            }
        }
        &._active {
            .payment-option-title {
                .action-toggle {
                    &:after {
                        content: $icon-up;
                    }
                }
            }
        }

        &._collapsible {
            .payment-option-title {
                cursor: pointer;
            }

            .payment-option-content {
                display: none;
            }
        }

        &.um-ordercomment {
            .payment-option-content {
                padding: $indent__base;

                form.order-comment-form {
                    max-width: 100%;

                    .payment-option-inner {
                        width: 100%;

                        textarea {
                            resize: none;

                            &::placeholder {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .payment-option-title {
        @include lib-css(border-top, $checkout-payment-option-title__border);
        @include lib-css(padding, $checkout-payment-option-title__padding 0);

        .action-toggle {
            @include lib-css(color, $checkout-payment-option-title__color);
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size         : $checkout-payment-option-title-icon__font-size,
                $_icon-font-line-height  : $checkout-payment-option-title-icon__line-height,
                $_icon-font-color        : $checkout-payment-option-title-icon__color,
                $_icon-font-color-hover  : $checkout-payment-option-title-icon__hover__color,
                $_icon-font-color-active : $checkout-payment-option-title-icon__color,
                $_icon-font-margin       : $checkout-payment-option-title-icon__margin,
                $_icon-font-position     : after
            );
        }
    }

    .payment-option-content {
        @include lib-css(padding, 0 0 $indent__base $checkout-payment-option-content__padding__xl);
    }

    .payment-option-inner {
        margin: 0 0 $indent__base;
    }

    .credit-card-types {
        padding: 0;

        .item {
            display: inline-block;
            list-style: none;
            margin: 0 $indent__xs 0 0;
            vertical-align: top;

            &._active {
                font-weight: $font-weight__bold;

                img {
                    filter: grayscale(0%);
                }
            }

            &._inactive {
                opacity: 0.4;
            }

            span {
                display: inline-block;
                padding-top: 6px;
                vertical-align: top;
            }
        }

        img {
            filter: grayscale(100%); // For Webkit browsers
            transition: all 0.6s ease; // Fade to color for Chrome and Safari
        }
    }

    .ccard {
        .legend {
            @extend .abs-visually-hidden;
        }

        .fields {
            > .year {
                padding-left: $indent__xs;
            }

            .select {
                padding-left: $indent__xs;
                padding-right: $indent__xs;
            }
        }

        .month {
            .select {
                width: 140px;
            }
        }

        .year {
            .select {
                width: 80px;
            }
        }

        .number {
            .input-text {
                width: 225px;
            }
        }

        > .field.cvv {
            > .control {
                padding-right: $indent__base;
                width: auto;
            }
        }

        .cvv {
            .input-text {
                width: 55px;
            }
        }

        &.fieldset {
            > .field {
                .fields.group.group-2 {
                    .field {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

.form-discount {
    display: flex;
    max-width: 100%;

    .payment-option-inner {
        margin: 0;
        width: 50%;
    }

    .actions-toolbar {
        max-width: 50%;
        padding: 0;
        margin-left: $indent__s;
        border-bottom: 1px solid $button-primary__gray;

        .primary {
            width: 100%;
            float: none;
        }
        .action-apply {
            @include lib-button-primary();
            background: $button-primary__gray;
            color: black;
            line-height: $line-height__base;

            &:after {
                display: none;
            }

            &:hover {
                background: $button-primary__gray;
                color: black;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .checkout-payment-method {
        .payment-option {
            .payment-option-title {
                @include lib-css(padding, $checkout-payment-option-title-mobile__padding)
            }

            .payment-option-content {
                @include lib-css(padding, 0 $checkout-payment-option-title-mobile__padding $indent__base);
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .checkout-payment-method {
        .payment-option-title {
            @include lib-css(padding-left, $checkout-payment-option-content__padding__xl);
        }
        .payment-option-content {
            .payment-option-inner {
                  margin-left: 0;
                }
            }
        }
    }

//
//  Buckaroo
//  _____________________________________________

.bank-types,
.card-types {
    margin-bottom: $indent__s;

    .bank-types-img,
    .card-types-img {
        margin-top: $indent__s;
        float: right;
    }
}

.payment-methods .payment-method-title .buckaroo_magento2_image_title {
    &:before {
        float: right;
    }
}
